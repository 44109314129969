// @ts-check
/* eslint-disable no-process-env */
// must be JS, consumed by next.config.mjs
import { getPurposityEnv } from 'purposity-env'

export const PURPOSITY_ENV = getPurposityEnv({
  VERCEL_ENV: process.env.VERCEL_ENV,
  NEXT_PUBLIC_VERCEL_ENV: process.env.NEXT_PUBLIC_VERCEL_ENV,
  NODE_ENV: process.env.NODE_ENV,
  DEBUG: process.env.DEBUG,
})

export const SENTRY_DSN =
  'https://55aec2b70bea43ed8efae394782619da@o546790.ingest.sentry.io/6142668'

export const IS_DEV = process.env.NODE_ENV === 'development'

export const PORT = process.env.PORT || 3030
